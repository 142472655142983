@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
    font-family: "Poppins", sans-serif;
}
h1, h2, h3, h4, h5, h6{
    font-family: "Poppins", sans-serif;
}
span, p, a, b, strong, input, label, small, em, button, div{
    font-family: "Poppins", sans-serif!important;
}
.fieldCS {
    width: 100%;
}
.InputField {
    margin-bottom: 20px;
    position: relative;
}
.imageWidth {
    width: 100%;
}
.signupcontainer {
    margin: auto;
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: inherit; 
    background: #F2EDF8;
    padding: 50px;
}
.Logincontainer {
    margin: auto;
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: inherit; 
    background: #F2EDF8;
    padding: 50px;

}
.googleLoginsvG svg{
    margin-right: 15px;
}
.passvisiblePass {
    position: absolute;
    right: 15px;
    bottom: 15px;
}
@media (max-width: 900px) {
    .Logincontainer{
        display: block;
    }
    .signupcontainer{
        display: block;
    }
}