.ulSideBtn {
    list-style: none;
    margin: 0;
    padding: 10px 15px;
}
.ulSideBtn button {
    width: 100%;
    text-align: left;
    justify-content: left;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #000000;
    font-weight: 700;
}
.maxWidth {
    width: 900px;
    margin: auto;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
    background-color: rgb(200 200 200 / 50%)!important;
   
}
#addTaskTitle, #addSubTaskTitle {
    display: block;
    width: 100%;
    padding: 10px 0px;
    border: 0px !important;
    font-size: 18px;
    font-weight: 600;
    background: transparent;
}
#addTaskTitle:focus, #addTaskDescription:focus, #addSubTaskTitle:focus, #addSubTaskDescription:focus{
    outline: 0px;
}
#addTaskDescription, #addSubTaskDescription {
    width: 100%;
    border: 0px;
    font-size: 14px;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
}
.dBlock h4 {
    margin-bottom: 0px;
    padding-top: 10px;
    color: #000000;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 0;

}
.dBlock p {
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 0px;
    color: #9c9c9c;
}

.dBlock small {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 0.7rem;
    display: block;
}
.iconSize{
    font-size: 14px!important;
    font-family: "Poppins", sans-serif;
}
.cardBlock {
    position: relative;
    cursor: pointer;
    padding: 0px 10px;
    border-bottom: 1px solid rgb(238, 238, 238);
}
.cardBlocksub {
    position: relative;
    cursor: pointer;
    padding: 10px 0px 10px 35px;
    border-bottom: 1px solid rgb(238, 238, 238);
}
.borderRadi {
    position: absolute;
    left: -35px;
    border-radius: 50%;
    border: 2.3px solid #6a6a6a;
    height: 17px;
    width: 17px;
    cursor: pointer;
    top: 10px;
}
.cardBlocksub .borderRadi {
    position: absolute;
    left: 10px;
    border-radius: 50%;
    border: 2.3px solid #6a6a6a;
    height: 12px;
    width: 12px;
    cursor: pointer;
    top: 16px;
}
.iconDefault {
    visibility: hidden;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: transform;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.tabActive{
    /* box-shadow: inset 1px 1px 32px 20px rgb(39 145 247 / 17%);
    border-radius: 10px; */
    /* box-shadow: inset 1px 1px 32px 20px rgb(39 145 247 / 23%); */
    background: #C49ADF;

    border-radius: 0px;
}
.ulSideBtn li {
    margin: 0px 0px 10px 0px;
    padding: 0px 0px;
}
s{
    color: #828282;
}
 
 
.cardBlock:hover .editIcon {
    cursor: pointer;
    visibility: visible;
}
.cardBlock:hover{
    background: #f8f8f8;
}
.cardBlocksub:hover .editIcon {
    cursor: pointer;
    visibility: visible;
}
.cardBlocksub:hover{
    background: #f8f8f8;
}
.editIcon {
    cursor: pointer;
    visibility: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
}
.DateCategory {
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    font-weight: 900;
    padding-bottom: 5px;
    padding-top: 30px;
}
.countSpan{
    text-align: right;
    width: 100%;
    color: #2791f7;
    font-size: 14px;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 200,
  'GRAD' -25,
  'opsz' 20
}

li.hamberPosAbs {
    /* position: absolute; */
    /* left: 0; */
    padding: 0px 0px;
    position: absolute;
    left: 22px;
    top: 8px;
    z-index: 99;
}
.mbslideAbs{
    box-shadow: 1px 2px 10px 0px #914CCA29;
    border-radius: 0px 20px 20px 0px;
}
@media (max-width: 900px) {
 .mbStyle{
    justify-content: end;
 }
 .mbRedSpace{
    margin-top: 40px;
    margin-bottom: 0px;
 }
 .DateCategory{
    padding: 10px;
 }
 .mbslideAbs{
    position: fixed;
    left: 0;
    z-index: 99;
    bottom: 0;
    top: 0;
    width: 70%!important;
  
 }
 li.hamberPosAbs{
    left: 10px;
 }
 .mbRightside{
    position: absolute;
    right: 0;
    width: 90%!important;
 }
 #addTaskTitle{
    font-size: 16px;
    }
    
 #addTaskDescription {
      font-size: 12px;
    }
     
}
.subTaskSection {
    padding: 0px 10px;
    border: 1px solid #d6d6d6;
    margin: 0px 15px;
}
input#Subtask-date {
    margin-left: 10px;
    border: 1px solid #919191;
    padding: 5px;
}
.subTaskSection #addSubTaskTitle {
    font-size: 15px;
}
.subTaskSection #addSubTaskDescription {
    font-size: 12px;
}
.Subtask-date, .Subtask-date input {
    font-size: 12px;
}
input#Subtask-date {
    margin-left: 5px;
    border: 1px solid #919191;
    padding: 2px;
}
.Subtask-date {
    margin-bottom: 10px;
}
.addSubTaskBtn {
    margin: 0px 10px 0px 10px;
}
.subTaskbutns{
    text-align: right;
    margin: 0px 10px;
}
.cardBlocksub h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}
.cardBlocksub p {
    font-size: 12px;
    padding: 0;
    margin: 0;
}
.subtaskView h3 {
    margin: 0;
    padding-left: 11px;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 5px;
    border-bottom: 1px solid #ededed;
}

.cardRes {
    box-shadow: 1px 2px 10px 0px rgba(119, 10, 151, 0.25);
    padding: 10px;
    margin: 10px 0px;
    border-radius: 5px;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    color: #444444;
}
.cardRes span {
    font-weight: 900;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.activeTab {
    background: #d8d8d8;
}
.dashbordActiveTab {
    background: #CFB1EA;
}
.logoDashboard {    
      width: 180px;
}
.menuRight{
    float: right;
}
.Topicons{
    width: 20px;
    color: #946bff;
}
.TopiconsHeader{
    
    color: #946bff;
}
.leftsidecontainer .MuiDrawer-paperAnchorDockedLeft {
    background-image: linear-gradient(171.09deg, #E0D8FF 11.64%, #FFD8FD 88.69%) !important;
    border-radius: 0px 20px 20px 0px !important;
}
.leftsideicons{
    position: absolute!important;
    right: 0px;    
}

.profilePicDash{
    width: 30px;
    border-radius: 50%;
}
.truncate span{
  font-weight: bold;
}
.totalCount{
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    line-height: 1.2;
}
.onWrKingTask {
    margin-top: 30px;
    margin-bottom: 10px;
}
.cardRes1 {
    box-shadow: 1px 2px 10px 0px rgba(119, 10, 151, 0.25);
    padding-top: 10px;
    border-radius: 5px;
}
.leftLabel{
    border: 3px solid #1957B0;
    padding: 5px 15px;
    border-radius:  5px;
    line-height: 0;
    font-weight: bold;
    color: #1957B0;
}
.heading{
     
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 30px;
}
.heading h4 {
font-weight: bold;
   
font-size: 20px;
}
.heading h5{
    font-size: 20px;

}
 .taskSection {
    margin-top: 10px;
    width: 100%;
 }

.taskSection h5 {
    font-size: 16px;
    /* line-height: 1.5; */
    color: #5b5b5b;
    font-weight: 300;
    margin-top: 7px;
}
.taskSection p{
    font-size: 12px;
    color: #303741;
    font-weight: 400;
    /* line-height: 1.5; */
}
.borderBottom{
    border-bottom: 1.5px solid #cfb1ea;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.editButton {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer
}
.editDiv{
    position: relative;
    cursor: pointer;
}
.editDiv:hover .editButton{
    visibility: visible;
}
.addtaskOutline{
    border: 1px solid #eee;
    margin: 10px 10px 0px 10px;
    padding: 0px 10px;
    border-radius: 20px;
}
.borderleft {
    border-left: 1px solid #eeeeee;
}
.inputForm{
     width: 100%;
}
.inputForm div{
    width: 100%;
    padding: 0;
    margin: 0;
}
.inputForm fieldset{
    padding: 0;
    margin: 0;
    border: none!important;
}
.taskSection h3{
    font-size: 16px;
    margin: 0 0px 5px 0px;
    line-height: 1.5;
    font-weight: 600;
    color: #1a1e23;
}
.descriptionDiv{
    margin-bottom: 20px;
    font-weight: 300;
    color: #303741;
    font-size: 14px;
}
.editAddButtonCancel {
    color: #444!important;
    text-transform: capitalize!important;
    padding: 4px 10px!important;
    font-size: 12px;
    font-weight: 600!important;
    border: 1px solid #444!important;
    line-height: 1;
}
.editAddButton {
    color: #ffffff!important;
    text-transform: capitalize!important;
    padding: 4px 10px!important;
    font-size: 12px;
    font-weight: 600!important;
    border: 1px solid #444!important;
    line-height: 1;
    background: #444!important;
}
.TopList li{
    text-transform: capitalize;
}
.commentSectionparent{
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin: 20px;
}
.commentSectionparent textarea{
    width: 100%;
    border: none!Important;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
}
:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
.commentName{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    display: block;
}

.commentDate{
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0;
    margin-left: 10px;
    align-content: center;
}
.commentText{
    display: block;
    font-size: 14px;
    font-weight: 300;
}
.commentPreview {
    display: inline-flex;
}
.cmtbtn{
    border: 1px solid #0055ff;
/* box-shadow: hsl(200, 0%, 100%) 0 2px 0 inset, hsla(215, 15%, 92%, 0.3) 0 -2px 0 inset, hsla(215, 15%, 89%, 0.5) 0 1px 2px 0; */
}
.activeComment{
    background: #cfb1ea33!Important;
    box-shadow: 0px 0px 0px #CFB1EA!Important;
}
.taskEdit{
    width: 100%;
    display: block;
}
